.f3cc {
  --f3cc-background: #e9e9e6;
  --f3cc-button-background: var(--white);
  --f3cc-button-foreground: var(--black);
  --f3cc-accept-background: var(--red);
  --f3cc-accept-foreground: var(--white);

  .f3cc-button {
    border-radius: 2.6em;
    font-weight: 700;
  }

  .f3cc-button.reject {
    border: 0.15em solid black;
  }

  .f3cc-button.accept {
    border: 0.15em solid var(--red);
  }
}
