:root {
  --container-padding: 1rem;
  --container-width: 48rem;

  --container-width-prose: 50.5rem;

  --container-width-content: 60.625rem;

  --container-width-extended: 108rem;

  @media (--media-md) {
    --container-width: 64rem;
  }
  @media (--media-lg) {
    --container-width: 85.125rem;
  }
}

.container {
  width: 100%;
  max-width: var(--container-width);
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);

  margin-left: auto;
  margin-right: auto;

  &.ce {
    max-width: var(--container-width-prose);
  }

  &.card {
    padding-bottom: 4rem;
  }

  .content {
    width: 100%;
    max-width: var(--container-width-content);
    margin-left: auto;
    margin-right: auto;
  }

  .container {
    padding-left: 0;
    padding-right: 0;
  }
}

.box-decoration {
  margin-top: 4rem;
}

main {
  flex-grow: 1;

  > .container:first-child:not(.hero) {
    margin-top: 4rem;
  }
}
