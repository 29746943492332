:root {
  --success-base: var(--green);
  --success-dark: var(--green-dark);
  --success-light: var(--green-light);

  --error-base: var(--red);
  --error-dark: var(--red-dark);
  --error-light: var(--red-light);
}

ul.messages {
  max-width: var(--container-width-prose);
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  li {
    width: 100%;
    border-radius: 0.1em;
    border: 0.15em solid;
    padding: 1em 2em;
    &.success {
      border-color: var(--success-base);
      color: var(--success-dark);
      background-color: var(--success-light);
    }
    &.error {
      border-color: var(--error-base);
      color: var(--error-dark);
      background-color: var(--error-light);
    }
  }
}
