.lead-container {
  display: grid;
  grid-template-columns: minmax(0, 1fr);

  @media (--media-lg) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .ce {
    grid-column: 1;
  }

  .button {
    margin-top: 2rem;
    margin-bottom: 2rem;
    --button-color: var(--red);
  }
}

.lead_0 {
  --container-width: var(--container-width-extended);
  background-color: var(--gray-dark);
  border-bottom-right-radius: var(--decoration-radius);
  color: var(--white);

  @media (--media-lg) {
    padding-right: max(0px, calc((100% - var(--container-width)) / 2));
  }

  .lead-container {
    position: relative;
    margin-right: 0;
  }

  h1 {
    text-align: left;
    font-size: clamp(2.5rem, 6vw, 4.875rem);
    line-height: 1.1;
    margin-top: clamp(3rem, 12vh, 6rem);
    margin-bottom: 3rem;
    max-width: 40rem;
  }

  p {
    margin-left: 2rem;
  }

  .ce--button {
    margin-bottom: 2rem;
  }

  .ce {
    &--lead_image {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      height: 20rem;

      margin-left: calc(-1 * var(--container-padding));
      margin-right: calc(-1 * var(--container-padding));

      max-width: calc(100% + 2 * var(--container-padding));
      width: calc(100% + 2 * var(--container-padding));

      grid-row: 1 /99;
      border-bottom-right-radius: var(--decoration-radius);

      @media (--media-lg) {
        max-width: 100%;
        width: 100%;

        margin-left: 6rem;
        margin-right: calc(-1 * var(--container-padding));

        height: unset;

        grid-row: 1 / 99;
        grid-column: 2;
        border-top-left-radius: var(--decoration-radius);
      }
    }
  }
}

.lead_1 {
  p {
    margin-left: 2rem;
  }

  .ce--button {
    margin-bottom: 2rem;
  }

  margin-top: calc(var(--lead-margin) + 5rem);
  @media (--media-lg) {
    margin-top: 4rem;

    .lead-container--right {
      margin-top: 10rem;
      margin-bottom: 4rem;
      margin-left: 6rem;
      grid-row: 1 / 99;
      grid-column: 2;
    }
  }
}

.lead_0,
.lead_1 {
  --container-width: var(--container-width-extended);

  @media (--media-lg) {
    --container-padding: 6rem;
  }
}
