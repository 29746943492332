.input-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.input-buttons input,
.custom-checkbox input {
  visibility: hidden;
  position: absolute;
}

label.custom-checkbox {
  display: inline-flex;
  gap: 0.5rem;
  margin-top: 1.5em;

  .icon {
    position: absolute;
    &.stroke {
      position: static;
      --icon-fill: var(--gray);
    }
  }
}

.comments__form,
.contact__form,
.issuebox__form,
.verify--form form {
  display: flex;
  flex-direction: column;

  fieldset {
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }

  input[type="text"],
  input[type="email"],
  textarea {
    margin-top: 1.5em;
    flex-basis: 100%;

    border: 1px solid var(--gray);
    padding: 0.625em 0.875em;
    border-radius: 1.33em;

    + label {
      display: block;
      position: absolute;
      top: 0.5em;
      left: 1.5em;
      font-size: 0.66em;

      opacity: 0;

      transition: opacity 0.2s ease-in;
    }

    &:not(:placeholder-shown) {
      & + label {
        opacity: 1;
      }
    }
  }

  textarea {
    height: 8rem;
  }

  .button {
    --button-color: var(--red);
    --button-color-contrast: var(--white);
    color: var(--red);
    margin-top: 1.5em;
    margin-right: auto;

    &:hover {
      color: var(--white);
    }
  }

  .form-error,
  .errorlist li {
    width: 100%;
    color: var(--red);
    margin-bottom: 0.5rem;
  }
  .errorlist li {
    margin-top: 1rem;
  }
}

.verify--form form {
  input[type="submit"] {
    float: right;
  }
}

.contact__form,
.contact__message {
  padding: 2rem 3rem;
}
.contact__title {
  margin-top: 0;
  margin-bottom: 1rem;
}
