:root {
  --decoration-foreground: var(--white);
  --decoration-color: var(--gray-dark);
  --decoration-radius: 2.5rem;
  --decoration-orientation: to top right;
  --decoration-box-padding: 3rem;

  --decoration-offset: clamp(12rem, 22vh, 16rem);
}

.box-decoration {
  position: relative;
  padding-top: var(--decoration-box-padding);
  margin-bottom: 4rem;

  &.decoration-offset {
    padding-top: calc(2 * var(--decoration-box-padding));
    margin-top: calc(var(--decoration-offset) - var(--decoration-box-padding));
  }

  .box {
    z-index: -1;
    position: absolute;

    background: var(--gray-dark);

    border-top-left-radius: var(--decoration-radius);
    border-bottom-left-radius: var(--decoration-radius);
    border-bottom-right-radius: var(--decoration-radius);

    top: 0;
    right: 0;
    left: 0;
    bottom: clamp(1rem, 10%, 6rem);

    @media (--media-lg) {
      left: calc(
        0.5 * (100% - var(--container-width)) - 2 * var(--container-padding)
      );
    }
  }

  /* auto invert */
  > * {
    color: var(--white);
    &.card,
    .card {
      color: var(--gray-dark);
    }
  }
}

.decorated {
  position: relative;
  --decoration-box: calc(2 * var(--decoration-radius));

  &--top-right {
    --decoration-orientation: to top right;
    &::before {
      left: 0;
      top: calc(-1 * var(--decoration-box));
    }
  }
  &--top-left {
    --decoration-orientation: to top left;
    &::before {
      right: 0;
      top: calc(-1 * var(--decoration-box));
    }
  }
  &--bottom-right {
    --decoration-orientation: to bottom right;
    &::before {
      left: 0;
      bottom: calc(-1 * var(--decoration-box));
    }
  }
  &--bottom-left {
    --decoration-orientation: to bottom left;
    &::before {
      right: 0;
      bottom: calc(-1 * var(--decoration-box));
    }
  }

  &::before {
    /* z-index: -1; */
    position: absolute;
    width: var(--decoration-box);
    height: var(--decoration-box);
    content: " ";

    /* safari safari safari safari safari safari safari safari safari safari safari safari safari safari safari */
    background: linear-gradient(
        var(--decoration-orientation),
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0) 25%,
        var(--decoration-foreground) 25%
      ),
      radial-gradient(
        circle,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0) 71%,
        var(--decoration-color) 72%
      );
  }
}

.offset {
  transform: translateY(calc(-1 * var(--decoration-offset)));
  & + * {
    margin-top: calc(-1 * var(--decoration-offset));
  }
}

.verify--form .box-decoration {
  .card {
    padding: 2rem 1.5rem;
    @media (--media-md) {
      padding: 2rem 3rem;
    }
    @media (--media-lg) {
      padding: 4rem 6rem;
    }
  }
  h1 {
    text-align: left;
    margin-top: 0;
  }
}
