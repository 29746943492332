:root {
  --icon-size: 1.5em;
  --icon-fill: var(--gray-dark);
}

.icon {
  width: var(--icon-size);
  height: var(--icon-size);
  fill: var(--icon-fill);
  flex-shrink: 0;

  &.stroke {
    stroke: var(--icon-fill);
    fill: none;
  }
}
