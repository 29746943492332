.comments {
  &__title {
    margin-top: 1.25em;
    font-size: 3rem;
    text-align: center;
  }
  &__comment,
  &__form,
  &__message {
    padding: 2rem 3rem;
    @media (--media-lg) {
      padding: 3rem 6rem;
    }
    h3 {
      margin-top: 0;
    }
  }
  &__form__title {
    margin: 0;
  }
}

.comments__list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  > *:last-child {
    margin-bottom: 2rem;
  }
}

.comments__comment {
  font-size: 1.25rem;
  .comment__meta {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;

    .nickname {
      font-size: 1.1em;
      font-weight: bold;
    }

    .datetime {
      font-size: 1rem;
      color: var(--gray);
    }
  }
}
