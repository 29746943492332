:root {
  --button-color: var(--gray-dark);
  --button-color-contrast: var(--white);
}

.btn,
.button,
.tag,
.input-buttons label {
  display: inline-flex;
  background-color: var(--button-color-contrast);

  font-weight: bold;

  text-align: center;
  align-items: center;
  color: var(--button-color);
  border: 0.15em solid var(--button-color);

  &--icon {
    gap: 0.5rem;
    --icon-fill: var(--button-color);
  }
}

.btn,
.button {
  border-radius: 2.6em;
  padding: 0.8em 1.5em;

  cursor: pointer;

  &:hover {
    background-color: var(--button-color);
    color: var(--button-color-contrast);
    --icon-fill: var(--button-color-contrast);
  }
}

a.btn,
a.button {
  text-decoration: none;
}

.tag,
.box-decoration .card .tag {
  font-size: 0.75rem;
  color: var(--red);
  --button-color: var(--red);
  border-radius: 1.8em;
  padding: 0.4em 0.75em;
}

.input-buttons label {
  /* font-size: 1rem; */
  --button-color: var(--red);
  border-radius: 2em;
  padding: 0.5em 1em;

  cursor: pointer;
}

.input-buttons label:hover,
.input-buttons input:checked + label {
  background-color: var(--button-color);
  color: var(--button-color-contrast);
  --icon-fill: var(--button-color-contrast);
}
