.issues__list {
  @media (--media-lg) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.issue {
  padding: 2rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__tags {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  &__subject {
    margin: 0;
    font-family: unset;
    hyphens: auto;

    font-weight: bold;
  }

  &__footer {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
