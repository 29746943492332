.issuebox__form {
  margin: 4rem auto;

  .input-buttons {
    justify-content: left;
  }
  .input-buttons label {
    --button-color: var(--gray-dark);
    --button-constrast-color: var(--gray-dark);
  }
  /* .input-buttons label:hover, */
  .input-buttons input:checked + label {
    --button-color: var(--red);
    --button-constrast-color: var(--white);
  }

  h3 {
    font-family: unset;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 2rem;
  }
}

.issuebox__message,
.box-step {
  padding: 1rem 1.5rem;
  @media (--media-md) {
    padding: 2rem 3rem;
  }
  @media (--media-lg) {
    padding: 3rem 6rem;
  }
}

.box-step {
  display: none;
  &.current {
    display: unset;
  }
}

.issuebox__control {
  margin-top: 2rem;
  display: flex;
  .button:last-child {
    margin-left: auto;
    margin-right: 0;
  }
}

.step-display {
  margin-bottom: 0.5rem;
}

.lead-container .issuebox-teaser {
  position: absolute;
  bottom: calc(-1 * (var(--lead-margin) + 2rem));
  left: var(--container-padding);
  right: var(--container-padding);
  margin-left: auto;
  margin-right: auto;

  @media (--media-lg) {
    bottom: -8rem;
    right: 4rem;
    left: auto;
  }

  padding: 2rem;

  max-width: 29.25rem;

  text-align: center;

  h2 {
    margin-top: 0;
  }

  p {
    margin-left: 0;
  }

  .button {
    margin-top: 1rem;
    margin-bottom: 0;

    --button-color: var(--white);
    --button-color-contrast: var(--red);
  }

  border-top-left-radius: var(--decoration-radius);
  border-top-right-radius: var(--decoration-radius);
  border-bottom-right-radius: var(--decoration-radius);

  background-color: var(--red);
  --decoration-color: var(--red);
}

.mail--sent {
  margin-top: 2rem;
  background-image: url(/static/assets/mail--sent.png);
  height: 10rem;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.issuebox__note {
  margin-top: 2rem;
  &__head {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    --icon-size: 1rem;

    span {
      font-weight: bold;
    }
  }
}
