.more-link,
.backlink__link {
  display: inline-flex;
  align-items: center;
  gap: 0.33em;

  span {
    font-weight: bold;
  }

  --icon-size: 1em;
  --icon-fill: var(--red);
}
.backlink__link {
  margin-right: auto;
}

.ce {
  &--download,
  &--button,
  &--embedded-video {
    margin-bottom: 2rem;
  }

  &--image {
    img {
      margin: 0 auto;
      border-radius: 1.25rem;
    }

    margin-top: 2em;
    margin-bottom: 2em;
  }

  figcaption,
  .embedded-video__caption {
    margin-top: 1em;
    font-style: italic;
    font-size: 1rem;
    color: var(--gray);
  }

  &--gallery figcaption {
    font-size: 0.75rem;
  }

  &--richtext {
    font-size: 1.25rem;
  }
}

body:not(.issuebox--list) .ce {
  &--image figcaption,
  .embedded-video__caption,
  &--richtext h2,
  &--richtext h3,
  &--richtext h4,
  &--richtext h5,
  &--gallery__title {
    &::after {
      content: "";
      margin-top: 0.5rem;
      display: block;
      width: 2rem;
      border-bottom: 0.15rem solid var(--red);
    }
  }
}

.lead {
  .ce--image {
    max-width: var(--container-width-content);
    margin-left: auto;
    margin-right: auto;
  }
  p {
    white-space: break-spaces;
    font-weight: bold;
  }
}

.backlink__container {
  margin-top: 2em;
  margin-bottom: 2em;
}

.issuebox--list,
.topics--list,
.topics--detail {
  .ce--richtext {
    text-align: center;
  }
}
