footer {
  min-height: 8rem;
  margin-top: 6rem;
}

.footer-dark {
  background: var(--gray-dark);
  color: var(--white);
  padding: 4rem 2rem;

  border-top-right-radius: 2rem;
  --decoration-radius: 2rem;
  --decoration-foreground: var(--gray-lighter);
  --decoration-color: var(--gray-dark);

  min-height: 6rem;
}

.footer-light {
  background: var(--gray-lighter);
  padding-bottom: 2rem;
  padding-left: 4rem;
  padding-right: 4rem;
  transform: translateY(2rem);
  --decoration-color: var(--gray-lighter);

  border-top-right-radius: 2rem;
  min-height: 6rem;
}

.container--footer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  .container--logo {
    align-items: flex-start;
  }
}

.footer-nav {
  margin-left: auto;
  .nav-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
