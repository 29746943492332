.meetings__list {
  @media (--media-md) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  @media (--media-lg) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  &__title {
    grid-column: 1 / -1;
    margin-bottom: -1rem;
    h2 {
      font-size: clamp(2rem, 6vw, 3rem);
      font-weight: bold;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  &__all,
  &__more {
    grid-column: 1 / -1;
    display: flex;
    .button {
      --button-color: var(--red);
      --button-color-contrast: var(--white);
      margin-left: auto;
      margin-right: auto;
    }
  }
  &__all {
    .button {
      margin-right: 0;
    }
  }

  .card {
    overflow: hidden;
  }

  .meeting {
    border-bottom: 1px solid var(--gray-light);
    padding-bottom: 1rem;

    &--large {
      padding: 2rem 3rem;
      border-bottom: none;
    }

    &__subject {
      margin: 1rem auto;
    }

    &__register {
      margin-top: 1rem;
      text-align: right;
    }

    &__description {
      margin-top: 1rem;
      white-space: break-spaces;
    }

    &__meta {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;

      justify-content: space-between;
      align-items: center;
    }

    &__tags {
      gap: 0.5rem;

      .current {
        --button-color: var(--white);
        --button-color-contrast: var(--red);
        color: var(--white);
        border: 0.15em solid var(--red);

        &::before {
          content: " ";
          display: inline-block;
          border-radius: 50%;

          width: 1em;
          height: 1em;

          background-color: var(--white);
          margin-right: 0.3rem;

          animation: point-background-toggle 2.5s infinite ease-in-out;
        }
      }
    }
  }
}

@keyframes point-background-toggle {
  0% {
    background-color: var(--white);
  }
  50% {
    background-color: var(--red);
  }
  100% {
    background-color: var(--white);
  }
}
