.supporters {
  &__teaser {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;

    p {
      margin-bottom: 0;
    }

    &__container {
      display: flex;
      flex-direction: column;
      gap: 3rem;
    }

    &--digifond {
      a {
        white-space: nowrap;
      }
    }
  }

  &__logos {
    margin-left: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
  }

  &__logo {
    max-width: 10rem;
    &--small {
      max-width: 6.25rem;
    }

    filter: grayscale(1);

    &:hover {
      filter: none;
    }
  }

  &__main-logo {
    max-width: 16rem;
    filter: grayscale(1);

    &:hover {
      filter: none;
    }
  }

  &__footer {
    padding-top: 3rem;
    padding-bottom: 0rem;
    &__description {
      color: var(--gray);
      text-align: center;
    }
    &__logos {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
      justify-content: center;
      align-items: center;
    }
  }
}
