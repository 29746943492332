.rating {
  --button-color: var(--red);
  --button-color-contrast: var(--gray-lighter);

  .button--rating {
    border-color: transparent;
    padding: 0.4em 0.75em;
    font-size: 0.75rem;

    &::before {
      content: " ";
      margin-right: 0.5em;
      width: 1.33em;
      height: 1.33em;
      background-image: url(/static/assets/thumb_red.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
    &.rated {
      &::before {
        background-image: url(/static/assets/thumb_white.png);
      }
      --button-color: var(--gray-lighter);
      --button-color-contrast: var(--red);

      &:hover {
        &::before {
          background-image: url(/static/assets/thumb_red.png);
        }
        --button-color: var(--gray-lighter);
        --button-color-contrast: var(--red);
      }
    }
    &:hover {
      &::before {
        background-image: url(/static/assets/thumb_white.png);
      }
    }
    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
