.container.ce.ce--gallery {
  max-width: var(--container-width-content);
}
.ce--gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(17.75rem, 1fr));
  gap: 2rem;
  margin-bottom: 4rem;

  &__title {
    grid-column: 1 / -1;
    margin-bottom: 0;
  }

  &__image {
    img {
      border-radius: 1rem;
    }
  }
}
