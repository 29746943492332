header {
  z-index: 90;

  background-color: var(--white);

  border-top: 0.33rem solid var(--red);
  padding: 1rem 0;

  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
}

.container--header {
  max-width: var(--container-width-extended);

  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.logo-image {
  &--dark,
  &--light {
    max-width: 9.375rem;
  }
}
