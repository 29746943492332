.articles--list .ce--richtext {
  text-align: center;
}

.articles__list {
  &__title {
    grid-column: 1 / -1;
    margin-bottom: -1rem;
    h2 {
      font-size: clamp(2rem, 6vw, 3rem);
      font-weight: bold;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  &__all,
  &__more {
    grid-column: 1 / -1;
    display: flex;
    .button {
      --button-color: var(--red);
      --button-color-contrast: var(--white);
      margin-left: auto;
      margin-right: auto;
    }
  }
  &__all {
    .button {
      margin-right: 0;
    }
  }
}

.articles-app,
.article-teaser-app {
  .article {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 0;
    box-shadow: unset;
    background-color: var(--gray-lightest);

    padding-bottom: 2rem;
    &:not(:last-of-type) {
      border-bottom: 1px solid var(--gray-light);
    }

    gap: 1em;

    @media (--media-md) {
      box-shadow: inherit;
      flex-flow: nowrap column;
      align-items: unset;
      border-radius: 1.25rem;
      background-color: var(--white);
      padding-bottom: 0;
      border-bottom: none;
      gap: 0;

      &:hover,
      &:focus-visible {
        box-shadow: 3px 3px 5px rgba(255, 0, 0, 0.5),
          -1px -1px 3px rgba(255, 0, 0, 0.25);
      }
    }

    text-decoration: none;
    overflow: hidden;

    &__image {
      order: 2;
      flex: 0 1 30%;
      @media (--media-md) {
        position: relative;
        flex: 0 1 auto;
        order: 0;
      }

      img {
        width: 100%;
        border-radius: 1rem;

        @media (--media-md) {
          border-radius: 0;
        }
      }
    }

    &__tags {
      flex: 1 0 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 0.33em;
      margin-bottom: 1em;

      @media (--media-md) {
        position: absolute;
        left: 2em;
        right: 2em;
        bottom: 0;
      }
    }

    &__info {
      position: relative;

      flex: 0 1 calc(70% - 1em);
      display: flex;
      flex-direction: column;
      gap: 1em;

      @media (--media-md) {
        flex: 1 0 auto;
        padding: 1.5em 2em;
      }
    }

    &__title {
      font-size: clamp(1.125rem, 3vw, 1.5rem);
      font-weight: bold;
    }

    &__lead {
      display: none;
      @media (--media-md) {
        display: block;
      }

      flex-grow: 1;
    }

    &__more {
      display: none;

      @media (--media-md) {
        display: block;
      }
    }

    &__rating {
      pointer-events: none;
    }

    &__footer {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      gap: 1em;
      margin-top: 0.5em;
    }
  }
}

.articles__filter {
  margin-bottom: 3rem;
}
.articles__search {
  display: flex;
  margin-bottom: 3rem;
  .searchfield {
    position: relative;
    margin: 0 auto;
    input {
      /* width: 15em;
      @media (--media-md) {
        width: 25em;
      } */

      color: var(--red);
      border: none;
      border-radius: 2em;
      padding: 0.5em 1em 0.5em 3em;
      border: 0.15em solid transparent;

      &:active,
      &:focus-visible {
        border-color: var(--red);
        outline: none;
      }

      &::placeholder {
        opacity: 1;
      }
    }
    .icon-search {
      position: absolute;
      left: 1em;
      top: 0.75em;
      --icon-size: 1.1em;
      --icon-fill: var(--red);
    }
    .icon-delete {
      position: absolute;
      right: 1em;
      top: 0.6em;
      --icon-size: 1.5em;
      --icon-fill: var(--red);
    }
  }
}

.articles--detail {
  .article-meta {
    &__container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 1rem;
    }

    &__info {
      color: var(--gray);
    }

    &__meta {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      align-items: center;
    }
  }
}
