:root {
  --black: #030303;
  --white: #ffffff;
  --gray-dark: #383737;
  --gray: #6b6b6b;
  --gray-light: #f0f0f0;
  --gray-lighter: #f3f3f3;
  --gray-lightest: #fafafa;

  --red: #e2001a;
  --red-light: #fdf1f1;
  --red-dark: #991b1c;
  --green: #31c48d;
  --green-light: #f1faf7;
  --green-dark: #05533f;
}
