.topic-selection {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;

  margin: 4rem auto;

  .button {
    --button-color: var(--red);
  }
}
