.lightbox-area {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--gray-dark);
  z-index: 95;
}
.lightbox {
  display: none;
}
.lightbox.active {
  display: block;
}
.lightbox__inner {
  max-height: 100vh;
}
.lightbox__img {
  max-height: 100%;
  margin-bottom: 2rem;
}
.lightbox-area.active {
  display: flex;
  justify-content: center;
  align-items: center;
}
.lightbox__control {
  display: flex;
  justify-content: space-between;
  margin: 2rem auto;
  .button {
    --button-color: var(--white);
    --button-color-contrast: var(--gray-dark);
  }
}
.lightbox-icon {
  --icon-size: 2rem;
  margin-right: 0;
}
.lightbox__caption {
  text-align: center;
  color: var(--white);
}
