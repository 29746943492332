.card.container.ce--coach-teaser {
  padding: 0;
  overflow: hidden;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.coach__teaser,
.coach {
  display: flex;
  flex-direction: column;
  @media (--media-md) {
    flex-direction: row;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    /* margin: 0.5rem 0.5rem 0; */
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 100px;
    max-width: 33%;
    border-radius: 1rem;

    @media (--media-md) {
      width: 33%;
      min-width: 150px;
      border-radius: 0;
    }
    overflow: hidden;
    img {
      max-width: 100%;
      @media (--media-md) {
        max-width: 150%;
      }
      /* @media (--media-lg) {
        max-width: 100%;
      } */
    }
  }

  &__info {
    padding: 1rem 0;
    display: flex;
    align-items: center;
    gap: 1rem;
    @media (--media-md) {
      padding: 2rem;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__name {
    margin: 0;
    font-size: clamp(1.125rem, 3vw, 1.875rem);
    line-height: 1.1;
  }

  &__vita {
    font-size: 1.125rem;
  }

  &__more {
    margin-top: auto;
  }
}

.coach {
  &__image {
    margin-bottom: 2rem;

    img {
      border-radius: 2rem;
    }
  }

  &__tag-description {
    font-weight: bold;
  }
  &__tag-description:not(:first-of-type) {
    margin-top: 1rem;
  }
}

.coach__teaser {
  @media (--media-md) {
    &:hover,
    &:focus-visible {
      box-shadow: 3px 3px 5px rgba(255, 0, 0, 0.5),
        -1px -1px 3px rgba(255, 0, 0, 0.25);
    }
  }

  &__vita {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.coaches__list {
  &--with-filter {
    margin-top: 3rem;
  }
  @media (--media-md) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  @media (--media-lg) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .card {
    overflow: hidden;
  }

  &__title {
    grid-column: 1 / -1;
    margin-bottom: -1rem;
    h2 {
      font-size: clamp(2rem, 6vw, 3rem);
      font-weight: bold;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  &__all,
  &__more {
    grid-column: 1 / -1;
    display: flex;
    .button {
      --button-color: var(--red);
      --button-color-contrast: var(--white);
      margin-left: auto;
      margin-right: auto;
    }
  }
  &__all {
    .button {
      margin-right: 0;
    }
  }

  .coach__teaser {
    border-bottom: 1px solid var(--gray-light);
    &--large {
      border-bottom: none;
    }
  }
}
