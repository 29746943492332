.hidden {
  display: none;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.default-list {
  /* background-color: var(--gray-lightest); */
  &.card {
    background-color: var(--gray-lightest);
  }
  display: grid;

  grid-template-columns: minmax(0, 1fr);
  padding: 2rem;
  gap: 2rem;

  @media (--media-md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (--media-lg) {
    padding: 3rem;
    gap: 3rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.default-meta {
  color: var(--gray);
  font-size: 0.75rem;
}

.card {
  border-radius: 2rem;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.1);
  background-color: var(--white);
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.sr-only {
  width: 0px;
  height: 0px;
  position: absolute;
  overflow: hidden;
}

.centered {
  text-align: center;
}

.embedded-video__container > div {
  position: relative;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.6615620214%;
}

.embedded-video__container > div iframe {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}
