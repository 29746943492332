:root {
  --nav-slice-width: 25rem;
}

.nav-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  flex-direction: column;

  @media (--media-lg) {
    flex-direction: row;
  }
}

.container--logo {
  display: flex;
  align-items: center;
  z-index: 91;

  @media (--media-lg) {
    margin-right: 4rem;
  }
}

.container--header {
  position: relative;
}

.container--menu {
  display: none;
  position: absolute;
  right: 0;
  width: 100%;
  max-width: var(--nav-slice-width);
  min-height: 100vh;
  transform: translateX(var(--nav-slice-width));
  background-color: var(--white);
  padding-top: 10rem;
  z-index: 90;

  flex-grow: 1;
  align-items: center;
  flex-wrap: wrap;
  gap: 4rem;
  flex-direction: column;

  @media (--media-lg) {
    padding-top: unset;
    display: flex;
    flex-direction: row;
    position: static;
    transform: unset;
    min-height: unset;
    max-width: unset;
    width: unset;
  }
}

.language-nav {
  .nav-list {
    flex-flow: nowrap row;
    gap: 0;
    .nav-list-item {
      padding: 0.5em;
      &:not(:last-of-type) {
        &::after {
          content: "|";
          margin-left: 0.5em;
        }
      }
    }
  }
}

.main-nav {
  font-size: 1.25rem;
}

.container--meta {
  display: flex;
  flex-direction: column;

  @media (--media-lg) {
    margin-left: auto;
    flex-direction: row;
  }

  align-items: center;
  gap: 1rem;
}

a.nav-link {
  text-decoration: none;
  .active & {
    color: var(--red);
  }
}

.nav-toggle {
  @media (--media-lg) {
    display: none !important;
  }

  margin-left: auto;
  z-index: 92;

  .button {
    border: none;

    padding: 0.66rem;
    font-size: 2rem;

    align-items: center;
  }
  .menu-is-open & {
    &--open {
      display: none;
    }
    &--close {
      display: block;
    }
  }
  &--open {
    display: block;
  }
  &--close {
    display: none;
  }
}

body {
  overflow-x: hidden;
  position: relative;

  &::after {
    content: " ";
    z-index: -1;
    position: absolute;
    inset: 0;
    opacity: 0;

    transition: opacity 0.3s ease-in-out;
  }

  &.menu-is-open {
    max-height: 100vh;
    overflow-y: hidden;

    &::after {
      z-index: 89;
      background-color: var(--black);
      opacity: 0.66;
    }
  }
}

.container--menu {
  .menu-is-open & {
    display: flex;
    animation: slide-in 0.3s ease-in-out both;
  }
}

@keyframes slide-in {
  from {
    transform: translateX(var(--nav-slice-width));
  }
  to {
    transform: translateX(0);
  }
}
