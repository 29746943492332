@font-face {
  font-family: LilitaOne;
  font-weight: bold;
  src: url(/static/assets/fonts/LilitaOne-Regular.woff2) format("woff2");
}
@font-face {
  font-family: Relevant;
  src: url(/static/assets/fonts/Relevant-Normal.woff2) format("woff2");
}
@font-face {
  font-family: Relevant;
  font-style: italic;
  src: url(/static/assets/fonts/Relevant-Normal-Italic.woff2) format("woff2");
}
@font-face {
  font-family: Relevant;
  font-weight: bold;
  src: url(/static/assets/fonts/Relevant-Bold.woff2) format("woff2");
}
@font-face {
  font-family: Relevant;
  font-weight: bold;
  font-style: italic;
  src: url(/static/assets/fonts/Relevant-Bold-Italic.woff2) format("woff2");
}

html {
  font-size: 16px;
  font-family: Relevant, Arial, Helvetica, sans-serif;
  color: var(--gray-dark);
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.4;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: LilitaOne, Arial, Helvetica, sans-serif;
  margin-top: 2em;
  margin-bottom: 1em;
}

h1 {
  font-size: 3rem;
  text-align: center;
}

h3 {
  font-size: 1.5rem;
}

p {
  margin-bottom: 1em;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: var(--red);
  }
}

a:not([class]) {
  color: var(--red);

  &:hover {
    text-decoration: underline;
  }
}
