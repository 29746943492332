.ce--future-teaser__container {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 2rem;

  @media (--media-lg) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 4rem;
  }
}

.ce--future-teaser {
  &__card {
    padding: 2rem 3rem;
    @media (--media-lg) {
      padding: 3rem 6rem;
    }

    background-color: var(--gray-lightest);

    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &__head {
    margin: 0 auto;
  }

  &__text {
    h2 {
      font-size: clamp(2rem, 6vw, 3rem);
      margin-top: 0;
      text-align: center;
    }
    p {
      text-align: center;
    }
  }

  &__image {
    img {
      max-height: 6rem;
      margin: 0 auto;
    }
  }

  .box-decoration {
    padding-top: 0;
    .box {
      border-top-right-radius: var(--decoration-radius);
      border-bottom-left-radius: 0;
      top: 1rem;
      left: 1rem;
      right: -1rem;
      bottom: -1rem;
    }
  }
}
